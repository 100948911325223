import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-root" }
const _hoisted_2 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_3 = {
  class: "wrapper d-flex flex-column flex-row-fluid",
  id: "kt_wrapper"
}
const _hoisted_4 = {
  class: "content d-flex flex-column flex-column-fluid",
  id: "kt_content"
}
const _hoisted_5 = {
  class: "post d-flex flex-column-fluid",
  id: "kt_post"
}
const _hoisted_6 = {
  id: "kt_content_container",
  class: "container-xxl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!
  const _component_KTCreateApp = _resolveComponent("KTCreateApp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_router_view)
              ])
            ])
          ]),
          _createVNode(_component_KTFooter)
        ])
      ])
    ]),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_KTUserMenu),
    _createVNode(_component_KTCreateApp)
  ], 64))
}